import { combineReducers } from "redux";
import auth from "./auth";
import layout from "./layout";
import navbar from "./navbar";

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
});

export default rootReducer;
